.main-container {
  position: relative;
  background-color: #f7f7f7;
  flex-grow: 1;
  .main-wrap {
    display: flex;
    height: calc(100vh - 81px);
    padding: 2.4rem;

    .left-container {
      height: 100%;
      .MuiGrid-item {
        height: 100%;
      }
    }
    .right-container {
      height: 100%;
      .MuiGrid-item {
        height: 100%;
        max-height: 50%;
      }
    }
  }
  .white-box-wrap {
    &.activity {
      height: 100%;
      max-height: 100%;
      .card-container {
        height: calc(100% - 65px);
      }
      .box-container {
        height: 100%;
        overflow-y: auto;
      }

      .current {
        color: #909090;
        font-size: 1.2rem;
      }
      .MuiList-root {
        padding: 0;

        .MuiListItem-root {
          height: 10rem;
          border-radius: 0.4rem;
          border: 0.1rem solid #dbe3ef;
          justify-content: space-between;
          &:not(:last-child) {
            margin-bottom: 0.8rem;
          }
        }
      }
    }
    &.notification {
      height: 100%;
      .title-wrap {
        width: 100%;
        display: flex;
        align-items: center;
      }
      .notification-list-container {
        height: calc(100% - 65px);
        padding-bottom: 24px;
      }
      .title-link {
        width: 2.4rem;
        height: 2.4rem;
      }
    }
    &.recently {
      height: 100%;

      .card-container {
        height: calc(100% - 65px);
        padding-top: 0;
      }
      .box-container {
        overflow-y: auto;
        height: 100%;
      }
      .title-wrap {
        border-bottom: 0;
      }
      .channelTitle {
        margin-bottom: 1.2rem;
        color: #4e5258;
        font-size: 1.8rem;
        font-weight: 400;
        line-height: 2.8rem;
      }
      .channelBreadCrumb,
      .channelSubInfo {
        color: #909090;
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1;
      }

      .channelSubInfo {
        margin-top: 0.6rem;
      }

      .recently-used {
        padding: 0;
        .MuiListItem-root,
        .list-item {
          height: 10rem;
          padding: 0;
          border: 0.1rem solid #ddd;
          border-radius: 0.4rem;
          &:not(:last-child) {
            margin-bottom: 0.8rem;
          }
        }
        .menu-list-left,
        .MuiFormControlLabel-root {
          flex: 1;
        }
        .MuiButtonBase-root {
          padding: 2.2rem 1.6rem;
        }
      }
    }
  }
  .card-container {
    padding: 2.4rem;
    .MuiDivider-root {
      margin: 0;
      border-color: #ddd;
    }

    .card-box {
      padding: 2.4rem;
      border-radius: 0;
      background: #fff;
      box-shadow: none;
      .user-name {
        color: #2196f3;
        font-size: 1.4rem;
        font-weight: 700;
      }
      .card-header {
        position: relative;
        display: flex;
        align-items: baseline;
        gap: 0.6rem;
        margin-bottom: 1.6rem;
        .user-name {
          flex-shrink: 0;

          .MuiLink-root {
            color: #2196f3;
          }
        }
        .link-item {
          display: flex;
          align-items: center;
          gap: 0.3rem;

          &:hover {
            color: #000;
            text-decoration: underline;
          }
        }
        .channel {
          color: #000;
          font-size: 1.4rem;
          font-weight: 400;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: 2rem;
          .channel-name {
            padding-left: 0.3rem;
            font-size: 1.4rem;
            font-weight: 700;
          }
        }
        .breadcrumb,
        .location {
          line-height: 2rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: 2rem;
        }
        .location {
          font-size: 1.2rem;
          color: #909090;
          line-height: 2rem;
        }
        .current {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: 2rem;
        }
        .chip {
          margin-left: auto;
        }
      }
      .card-content {
        padding: 0;

        .text-label {
          color: #6b6b6b;
          font-size: 1.4rem;
          font-weight: 700;
        }
      }
      .update-content {
        color: #6b6b6b;
        font-size: 1.4rem;
        font-weight: 400;
      }
      .update-date {
        display: block;
        margin-top: 0.8rem;
        color: #bbb;
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 2rem;
      }
    }
  }
  .notification-list-container {
    .MuiTabs-root {
      .MuiTabs-flexContainer {
        padding: 0 2.4rem;

        & + .MuiTabs-indicator {
          height: 0.3rem;
        }
      }
      .MuiButtonBase-root {
        min-width: auto;
        padding: 1rem 2.4rem;
        font-size: 1.6rem;
        font-weight: 400;
        color: #909090;
        text-transform: none;

        &.Mui-selected {
          color: #a50034;
        }
        .MuiBadge-badge {
          width: 0.6rem;
          height: 0.6rem;
          min-width: auto;
          border-radius: 50%;
          padding: 0;
        }

        &.badge-new {
          .MuiBadge-badge {
            background: #a50034;
          }
        }
      }
      .MuiTabs-indicator {
        background-color: #a50034;
      }
    }
    .tab-panel {
      height: calc(100% - 49px);
      .panel-wrap {
        overflow-y: auto;
        height: 100%;
        padding-right: 0.2rem;
        margin-right: 2rem;
      }
      .MuiList-root {
        margin-left: 2.4rem;
        padding: 0;
      }
      .MuiListItem-root {
        padding: 0;
      }
      .MuiListItemText-root {
        margin: 0;
        border-bottom: 0.1rem solid #ddd;

        .row {
          padding: 1.2rem 0;
          display: flex;
          align-items: center;
          &:hover {
            background: #f7f7f7;
            cursor: pointer;

            .MuiLink-root {
              color: #2196f3;
            }
          }
        }
        .true {
          .MuiTypography-root {
            color: #000;
          }
        }
        .col {
          display: inline-block;
          font-size: 1.4rem;
          &.col-01 {
            width: 3.5rem;
            text-align: center;
          }
          &.col-02 {
            width: 7.5rem;
            text-align: center;
          }
          &.col-03 {
            flex: 1;
          }
          &.col-04 {
            padding-right: 6px;
            display: inline-flex;
            align-items: center;
            .MuiTypography-root {
              margin-left: 10px;
              font-size: 1.2rem;
              color: #c6c6c6;
            }
          }
        }
        .MuiTypography-root {
          font-size: 1.4rem;
          color: #909090;
          font-weight: 400;
        }
      }
    }
  }
}

.favorite-pop-container {
  .pop-close {
    position: absolute;
    right: 3.2rem;
    top: 1.6rem;
  }
  .pop-content {
    .box-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.2rem;

      .box-header {
        justify-content: flex-start;

        .area-title {
          line-height: 2.4rem;
          padding: 0.4rem 0;
          color: #525252;
        }
      }

      .select-container {
        min-width: 22rem;
      }
    }
    .left {
      width: 50%;
      .box-header {
        justify-content: flex-start;

        .count {
          width: 2rem;
          height: 2rem;
          margin-left: 1rem;

          .number {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background: #a50034;
            font-size: 1.2rem;
            color: #fff;
          }
        }
      }
      .menu-box-wrap {
        height: 48.7rem;
        padding: 0 2.4rem 0 0;
        border: 0.1rem solid #ddd;
        border-radius: 0.8rem;
      }
      .box-scroll-wrap {
        margin: 2.4rem 0 0;
        padding: 0 0.4rem 0 2.4rem;
        max-height: 460px;
        overflow-y: auto;
        .inner-scroll-area {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          margin-bottom: 2.4rem;
        }
      }
      .MuiChip-root {
        min-height: 4rem;
        flex-shrink: 0;
        padding: 0.7rem 1.2rem;
      }
      .message-area {
        margin-top: 2.4rem;
        .message-text {
          text-align: center;
          color: #b91c1c;
          font-size: 1.2rem;
          font-weight: 400;
        }
      }
    }
    .right {
      width: 50%;

      .menu-box-wrap {
        .box-area {
          padding-right: 0.3rem;
          max-height: 48.7rem;
          overflow-y: auto;
        }
      }
    }

    .area-title {
      display: block;
      font-size: 1.6rem;
      font-weight: 700;
      color: #525252;
      line-height: 3.2rem;
    }

    .MuiGrid-root {
      margin: 0;
    }
    .fav-box-menu {
      padding: 1.2rem 2.4rem 2.4rem;
      border-radius: 0.8rem;
      background: #f7f7f7;
      .box-title {
        color: #909090;
        font-size: 1.4rem;
        font-weight: 700;
        padding-bottom: 1rem;
      }
      .form-control-wrap {
        display: flex;
        flex-wrap: wrap;
        gap: 10px 0;
        justify-content: space-between;
      }
      .menu-item-checkbox {
        width: 49%;
        padding: 1rem 1.2rem;
        background: #fff;
        border: 0.1rem solid #ddd;
        border-radius: 0.8rem;
        margin: 0;

        .MuiTypography-root {
          color: #909090;
        }

        &:hover {
          border-color: #2196f3;
        }
      }
    }
  }
  .pop-bottom {
    margin-top: 2.4rem;

    &.align-center {
      justify-content: center;
    }
    .btn {
      width: 15rem;
    }
  }
}

// ******************************************** Partner ***********************************************
.partner-main {
  .main-wrap {
    gap: 20px;
    .left-container {
      .MuiGrid-item {
        max-height: 50%;
      }
    }
    .white-box {
      .box-container {
        overflow-y: auto;
        height: 100%;
        padding-bottom: 1px;
      }
      .card-container {
        height: 100%;
        overflow: hidden;
        padding-top: 0;
      }
      .title-wrap {
        width: 100%;
        display: flex;
        align-items: center;

        .box-title-area {
          align-items: center;
        }
        .title-link {
          width: 2.4rem;
          height: 2.4rem;
          margin-left: 1.2rem;
        }
      }
    }
  }
  .white-box-wrap {
    &.recently {
      .recently-used {
        padding: 0;
        .MuiListItem-root,
        .list-item {
          height: 10rem;
          padding: 0;
          border: 0.1rem solid #ddd;
          border-radius: 0.4rem;
          &:not(:last-child) {
            margin-bottom: 0.8rem;
          }
        }
        .item-wrap {
          padding: 22px 16px;
          display: flex;
          width: 100%;
          .text-left-wrap {
            width: 100%;
          }
          .link-arrow {
            padding: 12px;

            min-width: auto;
          }
        }
        .menu-list-left,
        .MuiFormControlLabel-root {
          flex: 1;
        }
        .MuiButtonBase-root {
          padding: 2.2rem 1.6rem;
        }
      }
    }
    &.fail-log {
      .title-wrap {
        margin-bottom: 24px;
      }
    }
    &.support {
      height: 100%;

      .support-list-container {
        height: calc(100% - 65px);
        padding-bottom: 24px;
      }
    }
  }

  .support-list-container {
    .MuiTabs-root {
      .MuiTabs-flexContainer {
        padding: 0 2.4rem;

        & + .MuiTabs-indicator {
          height: 0.3rem;
        }
      }
      .MuiButtonBase-root {
        min-width: auto;
        padding: 1rem 2.4rem;
        font-size: 1.6rem;
        font-weight: 400;
        color: #909090;
        text-transform: none;

        &.Mui-selected {
          color: #a50034;
        }
        .MuiBadge-badge {
          width: 0.6rem;
          height: 0.6rem;
          min-width: auto;
          border-radius: 50%;
          padding: 0;
        }

        &.badge-new {
          .MuiBadge-badge {
            background: #a50034;
          }
        }
      }
      .MuiTabs-indicator {
        background-color: #a50034;
      }
    }
    .tab-panel {
      height: calc(100% - 49px);
      .panel-wrap {
        overflow-y: auto;
        height: 100%;
        padding-right: 0.2rem;
        margin-right: 2rem;
      }
      .MuiList-root {
        margin-left: 2.4rem;
        padding: 0;
      }
      .MuiListItem-root {
        padding: 0;
      }
      .MuiListItemText-root {
        margin: 0;
        border-bottom: 0.1rem solid #ddd;

        .row {
          padding: 1.2rem 0;
          display: flex;
          align-items: center;
          &:hover {
            background: #f7f7f7;
            cursor: pointer;

            .MuiLink-root {
              color: #2196f3;
            }
          }
        }
        .true {
          .MuiTypography-root {
            color: #000;
          }
        }
        .col {
          display: inline-block;
          font-size: 1.4rem;
          &.col-01 {
            width: 3.5rem;
            text-align: center;
          }
          &.col-02 {
            width: 7.5rem;
            text-align: center;
          }
          &.col-03 {
            flex: 1;
          }
          &.col-04 {
            padding-right: 6px;
            display: inline-flex;
            align-items: center;
            .MuiTypography-root {
              margin-left: 10px;
              font-size: 1.2rem;
              color: #c6c6c6;
            }
          }
        }
        .MuiTypography-root {
          font-size: 1.4rem;
          color: #909090;
          font-weight: 400;
        }
      }
    }
  }
  .fail-log-container {
    .log-wrap {
      display: flex;
      padding: 12px;
      border-bottom: 1px solid #ddd;
    }
    .name-area {
      width: 100px;
      .log-name {
        color: #2196f3;
        font-size: 14px;
        font-weight: 700;
      }
    }
    .log-area {
      color: #6b6b6b;
      font-size: 12px;
      .log-item {
        display: flex;
      }
      .label-text {
        color: #6b6b6b;
        font-size: 12px;
        font-weight: 700;
        flex-shrink: 0;
        margin-right: 4px;
      }
      .value-text {
        color: #6b6b6b;
        font-size: 12px;
        font-weight: 400;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .statistic-container {
    height: 100%;

    .statistic-area {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 20px 0 0;
      .date {
        color: #6b6b6b;
        font-size: 12px;
      }

      .area-top {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 12px;
        .top-left {
          display: flex;
          flex-shrink: 0;
          align-items: center;
        }
        .top-right {
          width: 100%;
          display: flex;
          flex: 1;
          justify-content: flex-end;
        }
      }
      .area-middle {
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
      }
      .field-area {
        display: flex;
        gap: 12px;
        align-items: center;
      }
      .field-item {
        min-width: 150px;
      }
    }
    .data-container {
      display: flex;
      gap: 12px;
      margin-bottom: 12px;

      .data-box {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 10px;
        width: 100%;
        padding: 12px 0;
        background: #f7f7f7;

        .box-inner {
          text-align: left;
        }
        .label {
          font-size: 12px;
        }
        .value {
          display: inline-flex;
          align-items: center;
          height: 40px;
          margin: 12px 0 0;
          color: #000;
          font-weight: 700;
          font-size: 24px;
          line-height: 16px;
        }
      }

      &.merge {
        background: #f7f7f7;
        flex-direction: column;
        padding: 20px 24px;
        border-radius: 10px;
        gap: 0;
        flex: 1;
        .box-wrap {
          display: flex;
          height: 100%;
          .data-box {
            background: none;
          }
          .value {
            margin: 0 0 12px;
            color: #a50034;
          }
        }
        .data-box {
          padding: 12px 0 0;
        }
      }
    }
  }
  .number-box {
    border-radius: 16px;
    padding: 4px 12px;
    display: flex;
    align-items: center;
    gap: 4px;
    &:before {
      content: '';
      width: 24px;
      height: 24px;
    }

    .text {
      font-size: 12px;
    }

    &.up {
      background-color: #0d9488;

      &:before {
        background: url(../../images/icon/ic_arr_up_24_outline_white.svg) no-repeat;
      }
      .text {
        color: #fff;
      }
    }
    &.down {
      background-color: #b91c1c;
      &:before {
        background: url('../../images/icon/ic_arr_down_24_outline_white.svg') no-repeat;
      }
      .text {
        color: #fff;
      }
    }
  }
}
