* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
* {
  font-family: 'Roboto', '맑은 고딕', '돋움', Dotum, 'Sans-serif', Arial;

  &::-webkit-scrollbar {
    width: 3px; /*스크롤바의 너비*/
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #b7bfca;
    border-radius: 100px;

    .dark & {
      background: #484848;
    }
  }

  &::-webkit-scrollbar-track {
    background-color: transparent; /*스크롤바 트랙 색상*/

    .dark & {
      background: #0b0b0b;
    }
  }
}
html {
  height: 100%;
  font-size: 10px;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
}
ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5 {
  font-weight: 500;
}
a {
  text-decoration: none;
}
