:root {
  --White: #fff;
  --Black: #000;
  --Basic-Background: #0c0c0c;
  --Basic-Basic: #1b1b1b;
  --Basic-Cool-Grey2: #262626;
  --Basic-Cool-Grey3: #5d5d5d;
  --Basic-Cool-Grey4: #808080;
  --Blue: #2196f3;
  --Basic-Grey: #b1b1b1;
  --Basic-placeholder: #424242;
  --Form-list_bg: #303030;
  --Basic-Background-e: #282828;
  --Basic-Cool-Grey1: #484848;
  --Basic-Cool-Grey2_e: #9f9f9f;
  --Basic-Cool-Grey3_e: #909090;
  --Basic-Cool-Grey4_e: #525252;
  --Basic-Cool-Grey3_e2: #9e9e9e;
  --Basic-Cool-Grey4: #e9e9e9;
  --Basic-Background-e3: #202020;
  --Form-list_bg-e: #161616;
  --Graph-graph_bg1_A: rgba(245, 158, 11, 0.1);
  --Graph-graph_bg2_B: rgba(185, 28, 28, 0.1);
  --Graph-graph_bg3_C: rgba(13, 148, 136, 0.1);
  --Graph-graph_bg4_D: rgba(33, 150, 243, 0.1);
  --Basic-Heritage-Red: #a50034;
}

$dark-bg-121212: #121212;
$darkmode-text: #f1f1f1;
$darkmode-white: var(--White);
$darkmode-242424: #242424;
$darkmode-border: rgba(183, 191, 202, 0.15);
$darkmode-525252: #525252;

.dark {
  background-color: var(--Basic-Background);

  .main-container {
    background: var(--Basic-Background);
  }
  // 공통

  .MuiDivider-root {
    border-color: var(--Basic-Cool-Grey1);
  }
  .component-view {
    background: var(--Basic-Background);
  }
  .btn-layer-popup {
    width: 32px;
    height: 32px;
    background: rgba(219, 227, 239, 0.2) url(../../images/icon/ic_sort_dark.png) no-repeat 50%;
  }
  .MuiPaper-root {
    > .MuiList-root {
      .MuiButtonBase-root:not(.MuiCheckbox-root) {
        color: var(--Basic-Cool-Grey4);
      }
      .MuiButtonBase-root {
        &.expand-selected {
          background: var(--Basic-Background);
        }
        &:hover {
          background: var(--Form-list_bg);
        }
      }
      .text-value {
        color: #b1b1b1 !important;
      }
    }
    .MuiMenu-paper {
      background-color: var(--Black);
      border-color: #1b1b1b;
      .header-wrap {
        .MuiDialogTitle-root {
          color: var(--White);
        }
      }
      .MuiMenuItem-root {
        color: var(--Basic-Cool-Grey4);
      }
    }
  }

  .type-button-dropdown {
    .btn-layer-popup {
      width: 32px;
      height: 32px;
      background: rgba(219, 227, 239, 0.2) url(../../images/icon/ic_sort_24_dark.png) no-repeat 50%;
    }
  }

  // 로그인
  .login-container {
    background: var(--Basic-Background);

    .login-top-area {
      .title {
        color: var(--Basic-Heritage-Red);
      }
      .sub-text {
        color: var(--Basic-Cool-Grey3);
      }
    }
    .login-input {
      .MuiInputBase-root {
        input {
          color: var(--Basic-Grey);
        }

        background: #1b1b1b;
      }
    }
    .notice-text,
    .copyright {
      color: var(--Basic-Cool-Grey3);
    }
    .MuiCheckbox-root {
      color: var(--Basic-Basic);
      background: var(--Basic-Basic);

      &.Mui-checked {
        background: $darkmode-white;
      }
    }
  }

  // Main
  .main-container {
    .content-wrap {
      background: var(--Basic-Background);
    }
    .white-box-wrap {
      &.recently {
        .title-wrap {
          border-bottom: 0;
        }
        .recently-used {
          .MuiListItem-root {
            border-color: var(--Basic-Cool-Grey1);
            background-color: var(--Form-list_bg-e);
          }
          .channelTitle {
            color: var(--White);
          }
        }
      }
      .box-top-label {
        .box-top-label {
          color: var(--White);
        }
      }
      .title-wrap {
        border-bottom: 0.1rem solid var(--Basic-Cool-Grey1);
        h2.MuiTypography-root {
          color: var(--White);
        }
      }
    }
    .card-container {
      .MuiDivider-root {
        border-color: var(--Basic-Cool-Grey1);
      }
      .card-box {
        .card-header {
          .channel {
            color: var(--White);
          }
          .location {
            color: var(--Basic-Cool-Grey3);
          }
          .current {
            color: var(--Basic-Cool-Grey3);
          }
          .link-item:hover {
            border-bottom: 1px solid var(--White);
          }
        }
        .card-content {
          .text-label {
            color: var(--Basic-Grey);
          }
        }
        .update-content {
          color: var(--Basic-Grey);
        }
        .update-date {
          color: var(--Basic-placeholder);
        }
      }

      .MuiPaper-root {
        background-color: transparent;
      }
    }
    .notification-list-container {
      .MuiTabs-root {
        .MuiButtonBase-root {
          &.Mui-selected {
            .MuiBadge-root {
              color: var(--Basic-Heritage-Red);
            }
          }
          .MuiBadge-root {
            color: var(--White);
          }
        }
      }
      .tab-panel {
        .MuiListItemText-root {
          .col {
            &.col-01 {
              .MuiTypography-root {
                color: var(--White);
              }
            }
            &.col-04 {
              .MuiTypography-root {
                color: var(--Basic-Cool-Grey2_e);
              }
            }
          }
        }
        .MuiListItemText-root {
          border-color: var(--Basic-Cool-Grey1);

          .row {
            &:hover {
              background: var(--Form-list_bg);
            }
          }
          .true {
            .MuiTypography-root {
              color: #fff;
            }
          }
        }
        .MuiTypography-root {
          color: var(--Basic-Cool-Grey3);
        }
      }
    }
  }

  .Btn-dark {
    .mode-label {
      color: $darkmode-white;
    }
    .MuiSwitch-root {
      .MuiSwitch-switchBase {
        &.Mui-checked {
          .MuiSwitch-thumb {
            color: var(--Basic-Heritage-Red);
          }
        }
      }
      .MuiSwitch-track {
        background: var(--Basic-Heritage-Red);
        opacity: 0.3;
      }
    }
  }

  // Top Bar
  .top-bar-container {
    .logo {
      .logo-text {
        .text {
          &.sm {
            color: $darkmode-white;
          }
        }
      }
    }
    .MuiPaper-root {
      background: var(--Basic-Basic);
      border-color: $darkmode-border;

      .time-zone {
        color: var(--white);
      }
      .btn-partner {
        background: #0d0d0d;
        color: $darkmode-white;
      }
      .profile-zone {
        .MuiButton-root {
          color: $darkmode-text;
        }
      }
    }
  }
  .top-title-container {
    background: var(--Basic-Basic);
    border-color: $darkmode-border;

    .title-area {
      .title {
        color: $darkmode-text;
      }
    }
  }
  // Gnb
  .navi-container {
    .MuiDrawer-root {
      .MuiCollapse-wrapper {
        .MuiButtonBase-root {
          &.selected {
            background: rgba(246, 229, 235, 0.1);
            color: #fff;

            .MuiTypography-root {
              color: var(--White);
            }
          }

          .btn-menu-expand,
          .btn-menu-shrink {
            &.selected {
              background: #262626;
            }
          }

          .MuiTypography-root {
            color: #fff;
          }
        }
      }
      .MuiButtonBase-root {
        .icon {
          &.iss {
            background: url(../../images/icon/ic_ch_ISS2_24_dark.svg) no-repeat;
          }
          &.inspect {
            background: url(../../images/icon/ic_ch_inspect3_32_dark.svg) no-repeat;
          }
          &.organization {
            background: url(../../images/icon/ic_ch_service_32_outline_dark.svg) no-repeat 50%;
          }
          &.promotion {
            background: url(../../images/icon/ic_ch_promo4_32_dark.svg) no-repeat 50%;
          }
          &.statistics {
            background: url(../../images/icon/ic_stastistic_32_outline_dark.svg) no-repeat 50%;
          }
          &.config {
            background: url(../../images/icon/ic_config_32_outline_dark.svg) no-repeat 50%;
          }
          &.monitoring {
            background: url(../../images/icon/ic_check_32_outline_dark.svg) no-repeat 50%;
          }

          &.favorite {
            background: url(../../images/icon/ic_favorite_off_32_outline_dark.svg) no-repeat 50%;
          }
        }
        .btn-menu-shrink.selected {
          background: #262626;
        }
      }
    }
    .MuiPaper-root {
      background-color: var(--Basic-Basic);

      .nav-divier {
        background: #242424;
      }
    }
  }

  // Breadcrumbs
  .MuiBreadcrumbs-ol {
    .MuiBreadcrumbs-li {
      &:nth-of-type(n + 7) {
        .breadcrumb-item {
          .MuiTypography-root {
            color: var(--Basic-Cool-Grey4);
          }
        }
      }
      &:nth-of-type(n + 8) {
        .breadcrumb-item {
          .MuiTypography-root {
            color: var(--White);
          }
        }
      }
    }
  }

  // Dialog
  .MuiDialog-container {
    .divider {
      .dark & {
        border-color: #323232;
      }
    }
  }
  .MuiDialog-paper {
    background-color: var(--Basic-Basic);
    .MuiTypography-root {
      color: $darkmode-text;

      &.MuiLink-root {
        color: var(--Blue);
      }
    }
  }
  .favorite-pop-container {
    .box-header {
      .area-title {
        color: var(--White);
      }
    }
    .pop-content {
      .fav-box-menu {
        background-color: var(--Basic-Background-e3);

        .box-title {
          color: var(--Basic-Cool-Grey3_e2);
        }
        .menu-item-checkbox {
          border: 1px solid var(--Basic-Cool-Grey1);
        }
        .menu-item-checkbox {
          background-color: var(--Basic-Basic);
        }
      }
      .left {
        .menu-box-wrap {
          border: 1px solid var(--Basic-Cool-Grey1);
        }
        .box-scroll-wrap {
          .inner-scroll-area {
            .MuiChip-root {
              background-color: var(--Basic-Background-e);
              border: 1px solid var(--Basic-Cool-Grey1);
            }
          }
        }
      }
    }
  }

  // Tooltip
  .MuiTooltip-popper {
    .MuiTooltip-tooltip {
      background: $darkmode-525252;
      color: $darkmode-white;
    }
  }

  // Table
  .table-container {
    .custom-table {
      table-layout: fixed;

      thead,
      .thead {
        .MuiTableCell-root,
        .th {
          background: var(--Basic-Background-e);
          border-bottom: 1px solid var(--Basic-Cool-Grey3);
          border-top: 1px solid var(--Basic-Cool-Grey3);
          color: var(--Basic-Grey);

          .MuiTableSortLabel-root {
            color: var(--Basic-Grey);
          }
        }
      }
      tbody,
      .tbody {
        .tr {
          &.created,
          &.added {
            td {
              background: rgba(33, 150, 243, 0.2);
            }
          }
          &.deleted,
          &.removed {
            td {
              background: rgba(165, 0, 52, 0.15);
            }
          }
          &.updated,
          &.update {
            td {
              background: rgba(13, 148, 136, 0.2);
            }
          }
          &.unsaved {
            td {
              background: rgba(165, 0, 52, 0.15);
            }
          }
        }

        td {
          background: #1b1b1b;
          color: #fff;

          .MuiTypography-root {
            color: #fff;
          }
          .MuiLink-root {
            color: var(--Blue);
          }
          &.yellow {
            background: rgb(108, 73, 12);
          }
          &.red {
            background: rgb(67, 19, 19);
          }
          &.green {
            background: rgb(13, 51, 48);
          }
          &.blue {
            background: rgb(7, 30, 48);
          }
        }
      }
      tfoot {
        td {
          background: var(--Basic-Background-e);

          &:first-child {
            color: var(--White);
          }
        }
      }

      &.data-table {
        thead {
          th {
            border-left: 1px solid var(--Basic-Cool-Grey1);
          }
        }
        tbody {
          td {
            border-left: 1px solid var(--Basic-Cool-Grey1);
          }
        }
      }
    }
  }
  //Pagination
  .pagination-container {
    background: var(--Basic-Basic);
  }

  // Box
  .white-box {
    background: var(--Basic-Basic);
    border-color: var(--Basic-Cool-Grey2);

    .box-content {
      .content-bottom-area {
        background: var(--Basic-Basic);
      }
    }

    .btn-expand {
      width: 24px;
      height: 24px;
      padding: 0;
      background: url('../../images/icon/ic_down3_24_outline_dark.png') no-repeat;
      box-shadow: none;
    }
  }
  .box-wrap {
    .title-wrap {
      border-bottom: 0.1rem solid var(--Basic-Basic);

      .MuiTypography-root {
        color: var(--White);
      }
    }
    .tab-content-area {
      .MuiTabs-root {
        border-color: $darkmode-border;
      }
    }
  }
  .MuiTextField-root.textarea {
    .MuiOutlinedInput-root {
      .MuiOutlinedInput-input:not(.Mui-disabled):read-only {
        & + .MuiOutlinedInput-notchedOutline {
          border-color: rgba(163, 163, 163, 0.4);
        }
      }
    }
  }

  // channel status
  .schedule-container {
    .schedule-wrap {
      border-top: 1px solid var(--Basic-Cool-Grey1);

      .channel-list-container {
        .top-area {
          background: var(--Black);
          border-bottom: 1px solid var(--Basic-Cool-Grey1);

          .text {
            color: var(--White);
          }
        }
      }
    }
    .channel-area {
      background: var(--Black);
      border-right: 1px solid var(--Basic-Cool-Grey1);
    }
  }
  .program-header-wrap {
    .header-inner {
      .header-item {
        background-color: var(--Basic-Background);
        border-bottom: 1px solid var(--Basic-Cool-Grey1);
        .text {
          color: var(--Basic-Cool-Grey3);
        }
      }
    }
  }
  .program-body-wrap {
    .program-item-wrap {
      .program-title {
        color: var(--Basic-Cool-Grey4);
      }
      .program-item {
        border-top: 1px solid #484848;
        border-right: 1px solid #484848;
        border-bottom: 1px solid #484848;

        &.past {
          background: var(--Black);
          border-top: 1px solid var(--Basic-Cool-Grey2);
          border-right: 1px solid var(--Basic-Cool-Grey2);
          border-bottom: 1px solid var(--Basic-Cool-Grey2);
          .program-title {
            color: var(--Basic-Cool-Grey2);
          }
        }
        &.active {
          background: var(--Basic-Basic);

          .program-title {
            color: $darkmode-white;
          }
          .program-time {
            color: var(--Basic-Cool-Grey3);
          }
        }
      }
    }
  }
  .detail-list-container {
    .label {
      color: var(--Basic-Cool-Grey3);
    }
    .value {
      border-bottom: 1px solid var(--Basic-Cool-Grey1);
      & > .text {
        color: var(--White);
      }
      &.no-border {
        border: 0;
      }
    }
  }
  .pop-channel-detail {
    .media-wrap {
      .title-area .category-title {
        color: var(--White);
      }
      .media-item-wrap {
        .item-row {
          .label {
            color: var(--Basic-Cool-Grey3);
          }
          .value {
            color: var(--White);
          }
        }
      }
    }
  }

  .btn-drop-paper {
    .MuiList-root {
      .text-label {
        color: var(--Basic-Cool-Grey4);
        border-bottom: 1px solid var(--Basic-Cool-Grey1);
      }
    }
  }
  .info-list-container.MuiList-root {
    .list-item {
      .label {
        color: var(--Basic-Cool-Grey4);
      }
    }
  }

  .show-count-wrap {
    .MuiTypography-roo.label {
      color: #909090;
    }
    .MuiTypography-roo.value {
      color: #a50034;
    }
  }
  .upload-info-text.MuiTypography-root {
    color: var(--Basic-Cool-Grey3);
    strong {
      color: var(--White);
    }
  }
  .type-category.detail-list-container {
    .info-value-wrap.bold {
      .label {
        color: var(--White);
      }
    }
  }

  .message-box {
    background: #282828;
  }

  .upload-box {
    background: var(--Basic-Background-e);
    border-color: var(--Basic-Cool-Grey1);

    > span {
      color: var(--Basic-Heritage-Red);
    }

    .upload-info-wrap {
      .file-name {
        color: var(--White);
      }

      .file-list-area {
        .item {
          color: var(--Basic-Cool-Grey2_e);
        }
      }
    }
  }

  .section-container {
    border-color: #484848;

    .sub-title {
      color: var(--White);
    }
  }

  // Channel mapping
  .channel-mapping {
    .feed-info {
      .feed-list-container {
        border-color: var(--Basic-Cool-Grey1);
      }
    }
  }

  // Media & Image
  .media-item-wrap {
    .item-row {
      .text {
        &.label {
          color: var(--Basic-Cool-Grey4);
        }
        &.value {
          color: var(--White);
        }
      }
    }
  }

  .upload-box {
    .upload-total-count {
      color: var(--White);
    }
  }

  .notice-text-list {
    .list-item {
      color: var(--Basic-Cool-Grey3);
    }
  }

  .field-wrap {
    border: 1px solid var(--Basic-Cool-Grey3);
  }

  .feed-info {
    .feed-list-container {
      .list-box {
        .MuiListItemText-secondary {
          color: var(--Basic-Cool-Grey3_e);
        }
      }
    }
  }

  .field-label-container {
    .field-value:not(.no-border) {
      border-bottom: 1px solid #484848;
    }
  }

  .MuiChartsAxis-root {
    .MuiChartsAxis-tickLabel,
    .MuiChartsAxis-line {
      stroke: #b1b1b1;
    }
    .MuiChartsAxis-label {
      fill: #b1b1b1;
    }
  }
  .MuiChartsLegend-series {
    text {
      font-size: 1.2rem !important;
      fill: #b1b1b1 !important;
    }
  }

  .title-selected-container {
    .content-empty-container {
      .empty-message {
        color: var(--Basic-Cool-Grey4);
      }
    }
  }

  .notification-container {
    .MuiTableRow-root {
      &.expanded {
        .MuiTableCell-root {
          background: var(--Black);
        }
      }
    }
    .dropwdown-content-wrap {
      .faq-title {
        border-bottom-color: var(--Basic-Cool-Grey1);
      }
      .dropwdown-box {
        background: var(--Form-list_bg);
      }
    }
    .dropwdown-content {
      .MuiTypography-root {
        background: var(--Form-list_bg);
      }
    }
    .total-count {
      .label {
        color: var(--White);
      }
    }
  }

  .infinite-scroll-table {
    .infinite-scroll-tbody {
      .MuiTableCell-root {
        background: var(--Basic-Basic);
      }
    }
  }

  .chart-board-container {
    .board-chart-wrap {
      .chart-area {
        .box-content {
          .MuiPieArcLabel-root {
            fill: var(--White);
            font-weight: 700;
          }
          .MuiChartsLegend-root {
            .MuiChartsLegend-series {
              text {
                fill: var(--White) !important;
              }
            }
          }
        }
      }
    }
    .button-area {
      .tab-box {
        border: 1px solid var(--Basic-Cool-Grey2);
        background: var(--Basic-Basic);
        &.active {
          .box-wrap {
            background: var(--Basic-Heritage-Red);
          }
          .label {
            color: var(--White);
          }
        }
        .box-wrap {
          background-color: var(--Basic-Basic);
        }
        .label {
          color: var(--Basic-Grey);
        }
        .value {
          color: var(--White);
        }
      }
    }
  }

  .partner-main {
    .statistic-container {
      .data-container {
        .data-box {
          background: var(--Basic-Basic);
          border: 1px solid var(--Basic-Cool-Grey2);

          .label {
            color: var(--Basic-Grey);
          }
          .value {
            color: var(--White);
          }
        }
        &.merge {
          background: var(--Basic-Background-e3);

          .data-box {
            border: 0;

            .value {
              color: var(--Basic-Heritage-Red);
            }
          }
        }
      }
    }
    .fail-log-container {
      .log-wrap {
        border-bottom: 1px solid var(--Basic-Cool-Grey1);
      }
    }
    .support-list-container {
      .tab-panel {
        .MuiListItemText-root {
          border-bottom: 1px solid var(--Basic-Cool-Grey1);
        }
        .MuiListItemText-root .row:hover {
          background: var(--Basic-Background-e);
        }
      }
      .tab-panel {
        .MuiListItemText-root {
          .true .MuiTypography-root {
            color: var(--White);
          }
        }
      }
    }
  }

  .monthly-picks-wrap {
    .pick-name {
      .label {
        color: #f1f1f1;
      }
    }
  }
  .weeklyPicker-container .weekly-date-text {
    color: var(--White);
  }
  .weekly-calendar-pop {
    .MuiDayCalendar-weekContainer {
      &:hover {
        .MuiPickersDay-root {
          background-color: var(--Basic-Cool-Grey1);
        }
      }
    }
  }
  .layout-container {
    .filter-label {
      color: var(--White);
    }
  }
  .media-detail-container {
    .media-wrap .title-area {
      .MuiTypography-root {
        color: var(--White);
      }
    }
  }
}
